import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const MainHomepage = lazy(() => import("./HomePage/index"));
const MainAbout = lazy(() => import("./AboutusPage/index"));
const MainIndex = lazy(() => import("./ProjectsPage/index"));
const MainCulture = lazy(() => import("./CulturePage/index"));
const MainService = lazy(() => import("./OurServicePage/index"));
const MainNews = lazy(() => import("./NewPage/index"));
const MainCommunity = lazy(() => import("./CommunityPage/index"));
const MainContact = lazy(() => import("./ContactPage/index"));
const BtnGalleryPage2 = lazy(() => import("./ProjectsPage/BtnGalleryPage2"));
const ProductBranding = lazy(() => import("./ProjectsPage/ProductBranding"));
const BtnGalleryPage3 = lazy(() => import("./ProjectsPage/BtnGalleryPage3"));
const ProductCampaign = lazy(() => import("./ProjectsPage/ProductCampaign"));
const BtnGalleryPage4 = lazy(() => import("./ProjectsPage/BtnGalleryPage4"));
const ProductCreative = lazy(() => import("./ProjectsPage/ProductCreative"));
const BtnGalleryPage1 = lazy(() => import("./ProjectsPage/BtnGalleryPage1"));
const AllProduct = lazy(() => import("./ProjectsPage/AllProduct"));

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainHomepage />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainAbout />
            </Suspense>
          }
        />
        <Route
          path="/projects"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainIndex />
            </Suspense>
          }
        />
        <Route
          path="/culture"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainCulture />
            </Suspense>
          }
        />
        <Route
          path="/service"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainService />
            </Suspense>
          }
        />
        <Route
          path="/news"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainNews />
            </Suspense>
          }
        />
        <Route
          path="/community"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainCommunity />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MainContact />
            </Suspense>
          }
        />
        <Route
          path="/gallery"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BtnGalleryPage2 />
            </Suspense>
          }
        />
        <Route
          path="/gallery_branding/:fieldName"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ProductBranding />
            </Suspense>
          }
        />
        <Route
          path="/gallery2"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BtnGalleryPage3 />
            </Suspense>
          }
        />
        <Route
          path="/gallery_campaign/:fieldName"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ProductCampaign />
            </Suspense>
          }
        />
        <Route
          path="/gallery3"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BtnGalleryPage4 />
            </Suspense>
          }
        />
        <Route
          path="/gallery_creative/:fieldName"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ProductCreative />
            </Suspense>
          }
        />
        <Route
          path="/gallery4"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BtnGalleryPage1 />
            </Suspense>
          }
        />
        <Route
          path="/gallery_all/:imageId"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AllProduct />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
